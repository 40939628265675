import React from 'react'
import { OfflineMessagePagination, OfflineMessage, leadsPagination, leads } from '../../types/knowledgebase.type'
import { Flex, VStack, Box, Heading, Text, TableContainer, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';
import { OfflineMessagesList } from '../../components/OfflineMessages/OfflineMessagesList';
import { NoDataOfflineMessages } from '../../components/Icons/noData/NoDataOfflineMessages';
import { OfflineMessageWindow } from '../../components/OfflineMessages/OfflineMessageWindow';
import { LeadsList } from '../../components/OfflineMessages/LeadsList';

type OfflineMessagesProps = {
  isChatListLoading: boolean;
  chatSessionsPage: leadsPagination;
  onPageChange: (page: number) => void;
  knowledgeBaseId: string;
  pageNumber: number;
}

export const LeadsNew = ({ isChatListLoading, chatSessionsPage, onPageChange, knowledgeBaseId, pageNumber }: OfflineMessagesProps) => {
  const [selectedChat, setSelectedChat] = React.useState<leads>(chatSessionsPage.results[0]);

  React.useEffect(() => {
    setSelectedChat(chatSessionsPage.results[0]);
  }, [chatSessionsPage])

  const handleSelectChat = React.useCallback((chatSession: leads) => {
    setSelectedChat(chatSession);
  }, []);

  if (!chatSessionsPage.results.length) {
    return (
      <VStack
        alignItems="center"
        direction="column"
        justifyContent="center"
        w="100%"
        h="100%"
        pt="10px"
        pb="10px"
        spacing={9}
      >
        <svg width="276" height="218" viewBox="0 0 276 218" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M55.6992 103.926C55.6992 100.101 58.8146 97 62.6576 97H215.741C219.584 97 222.699 100.101 222.699 103.926C222.699 107.751 219.584 110.852 215.741 110.852H62.6576C58.8146 110.852 55.6992 107.751 55.6992 103.926Z" fill="#F2EAFE" />
          <path d="M196.503 124.704H81.0767C84.9197 124.704 88.035 121.603 88.035 117.778C88.035 113.953 84.9197 110.852 81.0767 110.852H196.503C192.66 110.852 189.545 113.953 189.545 117.778C189.545 121.603 192.66 124.704 196.503 124.704Z" fill="#F2EAFE" />
          <path d="M196.503 150.778H81.0767C84.9197 150.778 88.035 147.677 88.035 143.852C88.035 140.027 84.9197 136.926 81.0767 136.926H196.503C192.66 136.926 189.545 140.027 189.545 143.852C189.545 147.677 192.66 150.778 196.503 150.778Z" fill="#F2EAFE" />
          <path d="M67.9786 130.815C67.9786 127.44 70.7275 124.704 74.1183 124.704H204.28C207.671 124.704 210.42 127.44 210.42 130.815C210.42 134.19 207.671 136.926 204.28 136.926H74.1183C70.7275 136.926 67.9786 134.19 67.9786 130.815Z" fill="#F2EAFE" />
          <path d="M72.8904 156.889C72.8904 153.514 75.6392 150.778 79.0301 150.778H199.368C202.759 150.778 205.508 153.514 205.508 156.889C205.508 160.264 202.759 163 199.368 163H79.0301C75.6392 163 72.8904 160.264 72.8904 156.889Z" fill="#F2EAFE" />
          <path d="M177.699 78H101.699C115.299 78 120.366 91 121.199 97.5V146H194.199V97.5C194.199 85.9 183.199 79.6667 177.699 78Z" fill="white" stroke="#495057" stroke-width="2" />
          <path d="M176.824 79H109.199C122.285 79 121.66 91.7234 122.462 98.0851V102H192.699V98.0851C192.699 86.7319 182.116 80.6312 176.824 79Z" fill="#ECEFF1" />
          <path d="M75.6992 99.5C75.6992 87.0736 85.7728 77 98.1992 77C110.626 77 120.699 87.0736 120.699 99.5V140H75.6992V99.5Z" fill="#495057" />
          <rect x="135.699" y="209" width="63" height="15" transform="rotate(-90 135.699 209)" stroke="#495057" stroke-width="2" />
          <rect x="137.699" y="207" width="59" height="4" transform="rotate(-90 137.699 207)" fill="#ECEFF1" />
          <path d="M129.699 111C129.699 109.895 130.595 109 131.699 109H177.699V113H131.699C130.595 113 129.699 112.105 129.699 111Z" fill="#495057" />
          <path d="M160.699 111H177.699V123H160.699V111Z" fill="#495057" />
          <rect x="65.6992" y="208" width="137" height="2" fill="#495057" />
          <rect x="75.6992" y="212" width="63" height="2" fill="#495057" />
          <path d="M65.6992 143C65.6992 141.343 67.0424 140 68.6992 140H120.699V146H68.6992C67.0424 146 65.6992 144.657 65.6992 143Z" fill="white" stroke="#495057" stroke-width="2" />
          <path d="M179.699 138H190.699M165.699 141L190.699 141M161.699 141L164.699 141" stroke="#8A929C" />
          <circle cx="143.122" cy="51.6689" r="7.78571" fill="#A774F6" />
          <circle cx="239.381" cy="210.214" r="6.28571" stroke="#7C2FF1" stroke-width="3" />
          <path d="M13.9704 58.0389C13.9708 58.1891 13.9323 58.3369 13.8586 58.4678C13.785 58.5987 13.6787 58.7083 13.5502 58.786L5.58309 63.6598C5.44876 63.742 5.29492 63.787 5.13744 63.7899C4.97996 63.7928 4.82456 63.7536 4.68729 63.6764C4.55132 63.6004 4.43805 63.4895 4.35914 63.3552C4.28022 63.2209 4.23851 63.068 4.23828 62.9122V53.1657C4.23851 53.0099 4.28022 52.857 4.35914 52.7227C4.43805 52.5884 4.55132 52.4775 4.68729 52.4015C4.82456 52.3243 4.97996 52.2851 5.13744 52.288C5.29492 52.2909 5.44876 52.3358 5.58309 52.4181L13.5502 57.2919C13.6787 57.3696 13.785 57.4792 13.8586 57.6101C13.9323 57.741 13.9708 57.8887 13.9704 58.0389Z" fill="#A774F6" />
          <path d="M275.301 93.5173C275.301 93.6675 275.262 93.8153 275.189 93.9462C275.115 94.0771 275.009 94.1867 274.88 94.2644L266.913 99.1382C266.779 99.2204 266.625 99.2653 266.468 99.2683C266.31 99.2712 266.155 99.232 266.017 99.1548C265.881 99.0788 265.768 98.9679 265.689 98.8336C265.61 98.6993 265.569 98.5464 265.568 98.3906V88.6441C265.569 88.4883 265.61 88.3354 265.689 88.2011C265.768 88.0668 265.881 87.9559 266.017 87.8799C266.155 87.8026 266.31 87.7635 266.468 87.7664C266.625 87.7693 266.779 87.8142 266.913 87.8965L274.88 92.7703C275.009 92.8479 275.115 92.9576 275.189 93.0885C275.262 93.2194 275.301 93.3671 275.301 93.5173Z" fill="#A774F6" />
          <path d="M101.49 4.6181H100.99V4.1181V0.772168C100.99 0.688781 100.966 0.634096 100.911 0.578792C100.856 0.523486 100.801 0.500031 100.718 0.500031H99.1734C99.09 0.500031 99.0353 0.523486 98.98 0.578792C98.9247 0.634096 98.9013 0.688781 98.9013 0.772168V4.1181V4.6181H98.4013H95.0553C94.972 4.6181 94.9173 4.64155 94.862 4.69686C94.8067 4.75216 94.7832 4.80684 94.7832 4.89023V6.43451C94.7832 6.51789 94.8067 6.57258 94.862 6.62788C94.9173 6.68319 94.972 6.70664 95.0553 6.70664H98.4013H98.9013V7.20664V10.5526C98.9013 10.636 98.9247 10.6906 98.98 10.7459C99.0353 10.8013 99.09 10.8247 99.1734 10.8247H100.718C100.801 10.8247 100.856 10.8013 100.911 10.7459C100.966 10.6906 100.99 10.636 100.99 10.5526V7.20664V6.70664H101.49H104.836C104.919 6.70664 104.974 6.68319 105.029 6.62788C105.084 6.57258 105.108 6.51789 105.108 6.43451V4.89023C105.108 4.80684 105.084 4.75216 105.029 4.69686C104.974 4.64155 104.919 4.6181 104.836 4.6181H101.49Z" fill="#495057" stroke="#7C2FF1" />
          <path d="M231.596 17.7592V16.2149C231.596 16.0005 231.521 15.8181 231.371 15.668C231.221 15.5179 231.039 15.4428 230.824 15.4428H227.478V12.0969C227.478 11.8824 227.403 11.7001 227.253 11.5499C227.103 11.3998 226.92 11.3247 226.706 11.3247H225.162C224.947 11.3247 224.765 11.3998 224.615 11.5499C224.465 11.7001 224.39 11.8824 224.39 12.0969V15.4428H221.044C220.829 15.4428 220.647 15.5179 220.497 15.668C220.347 15.8181 220.271 16.0005 220.271 16.2149V17.7592C220.271 17.9737 220.347 18.156 220.497 18.3061C220.647 18.4563 220.829 18.5314 221.044 18.5314H224.39V21.8773C224.39 22.0918 224.465 22.2741 224.615 22.4242C224.765 22.5743 224.947 22.6494 225.162 22.6494H226.706C226.92 22.6494 227.103 22.5743 227.253 22.4242C227.403 22.2741 227.478 22.0918 227.478 21.8773V18.5314H230.824C231.039 18.5314 231.221 18.4563 231.371 18.3061C231.521 18.156 231.596 17.9737 231.596 17.7592Z" fill="#7C2FF1" />
          <path d="M30.5825 118.266V116.721C30.5825 116.507 30.5074 116.325 30.3573 116.174C30.2071 116.024 30.0248 115.949 29.8104 115.949H26.4644V112.603C26.4644 112.389 26.3894 112.207 26.2392 112.056C26.0891 111.906 25.9068 111.831 25.6923 111.831H24.148C23.9335 111.831 23.7512 111.906 23.6011 112.056C23.4509 112.207 23.3759 112.389 23.3759 112.603V115.949H20.0299C19.8155 115.949 19.6332 116.024 19.483 116.174C19.3329 116.325 19.2578 116.507 19.2578 116.721V118.266C19.2578 118.48 19.3329 118.662 19.483 118.813C19.6332 118.963 19.8155 119.038 20.0299 119.038H23.3759V122.384C23.3759 122.598 23.4509 122.781 23.6011 122.931C23.7512 123.081 23.9335 123.156 24.148 123.156H25.6923C25.9068 123.156 26.0891 123.081 26.2392 122.931C26.3894 122.781 26.4644 122.598 26.4644 122.384V119.038H29.8104C30.0248 119.038 30.2071 118.963 30.3573 118.813C30.5074 118.662 30.5825 118.48 30.5825 118.266Z" fill="#712BDB" />
        </svg>

        <Box textAlign="center">
          <Heading
            maxW="580px"
            fontSize="xl"
            fontWeight="500"
            as="h3"
            mb={4}
            color="gray.500"
            lineHeight="medium"
            textAlign="center"
          >
            Leads by your customers will appear here.
          </Heading>
        </Box>
      </VStack>
    )
  }

  return (
    <Flex w="100%">
      <LeadsList
        isChatListLoading={isChatListLoading}
        chatSessionsPage={chatSessionsPage}
        selectedChat={selectedChat}
        onSelectChat={handleSelectChat}
        onPageChange={onPageChange}
        knowledgeBaseId={knowledgeBaseId}
        pageNumber={pageNumber}
      />
      {/* <OfflineMessageWindow offlineChatData={selectedChat} /> */}
    </Flex>
  )
}