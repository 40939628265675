import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../config';
import { url } from 'inspector';
interface Filter {
  field: string; 
  operator: string; 
  value: string; 
}

interface MyData {
  limit: string; 
  categories: string[]; 
  country: string; 
  filter: Filter[]; 
  statusCode: number; 
  message: string;
}

export async function setInviteUser(searchData: any): Promise<AxiosResponse<MyData>> {
	return await axios({
		baseURL: baseURL,
		method: 'POST',
    data: searchData,
		url: 'project/inviteUsers/',
	});
}
export async function getInviteUser(page: number, pageSize: number, searchKeyword: string): Promise<AxiosResponse<MyData>> {
  let getUrl = "";
  if(searchKeyword?.length > 0){
    getUrl = `project/inviteUsersDetails?search=${searchKeyword}&page_size=${pageSize}&page=${page}`;
  }else{
    getUrl = `project/inviteUsersDetails?page_size=${pageSize}&page=${page}`
  }
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: getUrl,
	});
}

export async function ScrapLeadsReinvite(id: number): Promise<AxiosResponse<MyData>> {
	return await axios({
		baseURL: baseURL,
		method: 'POST',
		url: `/project/${id}/ScrapLeadsReinvite`,
	});
}


export async function ScrapUserProfile(id: number): Promise<AxiosResponse<MyData>> {
	return await axios({
		baseURL: baseURL,
		method: 'get',
		url: `/project/${id}/ScrapUserProfile`,
	});
}
