const MessageIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.585786 0.584932C0.960859 0.209859 1.46957 -0.000854492 2 -0.000854492H14C14.5304 -0.000854492 15.0391 0.209859 15.4142 0.584932C15.7893 0.960005 16 1.46871 16 1.99915V6.49915C16 6.63175 15.9473 6.75893 15.8536 6.8527C15.7598 6.94647 15.6326 6.99915 15.5 6.99915C15.3674 6.99915 15.2402 6.94647 15.1464 6.8527C15.0527 6.75893 15 6.63175 15 6.49915V3.38215L8 7.58215L6.674 6.78715L1.034 10.2581C1.09101 10.4708 1.21658 10.6586 1.39124 10.7926C1.5659 10.9266 1.77988 10.9992 2 10.9991H7.5C7.63261 10.9991 7.75979 11.0518 7.85355 11.1456C7.94732 11.2394 8 11.3665 8 11.4991C8 11.6318 7.94732 11.7589 7.85355 11.8527C7.75979 11.9465 7.63261 11.9991 7.5 11.9991H2C1.4713 11.9992 0.964097 11.7898 0.58931 11.4169C0.214524 11.044 0.00264352 10.5378 0 10.0091V1.99915C0 1.46871 0.210714 0.960005 0.585786 0.584932ZM1 9.10415L5.708 6.20715L1 3.38215V9.10415ZM1 1.99915V2.21615L8 6.41615L15 2.21615V1.99915C15 1.73393 14.8946 1.47958 14.7071 1.29204C14.5196 1.1045 14.2652 0.999146 14 0.999146H2C1.73478 0.999146 1.48043 1.1045 1.29289 1.29204C1.10536 1.47958 1 1.73393 1 1.99915Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9749 12.974C15.6313 12.3176 16 11.4274 16 10.4991C16 9.57089 15.6313 8.68065 14.9749 8.02427C14.3185 7.3679 13.4283 6.99915 12.5 6.99915C11.5717 6.99915 10.6815 7.3679 10.0251 8.02427C9.36875 8.68065 9 9.57089 9 10.4991C9 11.4274 9.36875 12.3176 10.0251 12.974C10.6815 13.6304 11.5717 13.9991 12.5 13.9991C13.4283 13.9991 14.3185 13.6304 14.9749 12.974ZM12.1464 8.64559C12.2402 8.55182 12.3674 8.49915 12.5 8.49915C12.6326 8.49915 12.7598 8.55182 12.8536 8.64559C12.9473 8.73936 13 8.86654 13 8.99915V9.99915H14C14.1326 9.99915 14.2598 10.0518 14.3536 10.1456C14.4473 10.2394 14.5 10.3665 14.5 10.4991C14.5 10.6318 14.4473 10.7589 14.3536 10.8527C14.2598 10.9465 14.1326 10.9991 14 10.9991H13V11.9991C13 12.1318 12.9473 12.2589 12.8536 12.3527C12.7598 12.4465 12.6326 12.4991 12.5 12.4991C12.3674 12.4991 12.2402 12.4465 12.1464 12.3527C12.0527 12.2589 12 12.1318 12 11.9991V10.9991H11C10.8674 10.9991 10.7402 10.9465 10.6464 10.8527C10.5527 10.7589 10.5 10.6318 10.5 10.4991C10.5 10.3665 10.5527 10.2394 10.6464 10.1456C10.7402 10.0518 10.8674 9.99915 11 9.99915H12V8.99915C12 8.86654 12.0527 8.73936 12.1464 8.64559Z" fill="white"/>
    </svg>
    
  )
}

export default MessageIcon;