import * as React from "react";
import {
	Box,
	Button,
	Flex,
	Heading,
	SimpleGrid,
	Spinner,
	Tooltip,
	HStack,
	Link as ChakraLink,
	useToast,
	VStack,
	Text,
} from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";

import { useEffect } from "react";
import {
	MediaListItem,
} from "../../components/MediaListItem/MediaListItem";
import { User } from '../../services/appConfig';
import { CurrentUser } from "../../services/appConfig";
import { NoDataSubscribeIcon } from "../../components/Icons/noData/NoDataSubscribeIcon";
import { NoDataProjectIcon } from "../../components/Icons/noData/NoDataProjectIcon";
import { deleteKnowledgebase, fetchKnowledgebases, generateEmbeddings } from "../../services/knowledgebaseService";
import { SELF_HOST } from "../../config";
import { EmailPlusIcon } from "../../components/Icons/Icon";
import styles from "./ChatbotList.module.scss";
let PAID_ONLY = false;

if (SELF_HOST === 'true') {
	PAID_ONLY = false;
}

export const ChatbotList = () => {
	const toast = useToast();
	const [chatbotsList, setChatBotList] = React.useState<any>();
	const [chatbotsLoaded, setChatbotsLoaded] = React.useState<boolean>(false);

	const [user, setUser] = React.useState<User>(CurrentUser.get());
	React.useEffect(() => {
		async function fetchData() {
			try {
				const userData = CurrentUser.get();
				setUser(userData);
			} catch (error) {

			} finally {
			}
		}
		fetchData();
	}, [])
	const history = useHistory();


	const [createChatBotLoading, setCreateChatBotLoading] = React.useState<any>({});


	const onMenuItemClick = React.useCallback(
		async (type, chatbot) => {
			if (type === "edit") {
				history.push(`/app/edit-chatbot/${chatbot._id}/`);
			} else if (type === "getCode") {
				history.push(`/app/edit-chatbot/${chatbot._id}/?tab=add-to-site`);
			} else if (type === "customize") {
				history.push(`/app/edit-chatbot/${chatbot._id}/?tab=settings`);
			} else if (type === "delete") {
				await deleteKnowledgebase(chatbot._id as string);
				setChatBotList(chatbotsList?.filter((_chatbot: any) => _chatbot._id !== chatbot._id));
				toast({
					title: `Chat bot has been deleted successfully `,
					status: "success",
					isClosable: true,
				});
			}
		},
		[history, chatbotsList, toast]
	);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetchKnowledgebases();
				setChatbotsLoaded(true);
				setChatBotList(response.data);
			} catch (error) {

			} finally {
			}
		}
		fetchData();
	}, []);

	const getNoDataIcon = React.useCallback(() => {
		if (user && PAID_ONLY && user?.subscriptionData?.name === 'FREE') {
			return <VStack
				alignItems="center"
				direction="column"
				justifyContent="center"
				w="100%"
				h="100%"
				pt={32}
				pb={32}
				spacing="9"
			>
				<NoDataSubscribeIcon width="auto" height="250px" />
				<Box textAlign="center">
					<Heading
						maxW="480px"
						fontSize="xl"
						fontWeight="500"
						as="h3"
						mb="4"
						color="gray.500"
						lineHeight="medium"
						textAlign="center"
					>
						Upgrade to a paid plan or purchase credits to create chatbots.
					</Heading>
					<HStack justify="center">
						<Link to="/app/settings/subscription">
							<Button colorScheme='blue' size='md'>Subscribe Now</Button>
						</Link>
						<ChakraLink href={'https://ultimachatbot.lemonsqueezy.com/buy/881a9491-5b7b-44e2-acdf-cdf2521fb1f4?checkout[email]=' + user.email}
							isExternal >
							<Button variant='outline' colorScheme='blue' size='md'>Buy 50K tokens for $5</Button>
						</ChakraLink>
					</HStack>
				</Box>
			</VStack>
		}
		return (
			<VStack
				alignItems="center"
				direction="column"
				justifyContent="center"
				w="100%"
				h="100%"
				pt={25}
				// pb={32}
				spacing="9"
			>
				<NoDataProjectIcon width="auto" height="250px" />
				<Box textAlign="center">
					<Heading
						maxW="580px"
						fontSize="xl"
						fontWeight="500"
						as="h3"
						mb="4"
						color="gray.500"
						lineHeight="medium"
						textAlign="center"
					>
						Train Chat GPT on your website's data and create a chatbot within minutes
					</Heading>

				</Box>
			</VStack>
		);
	}, [user]);


	const createChatBot = React.useCallback(async (id) => {
		try {
			setCreateChatBotLoading({
				[id]: true
			});
			await generateEmbeddings(id);
			setCreateChatBotLoading({
				[id]: false
			})
		} catch (error) { }
	}, []);

	const getChatbotsList = React.useCallback(() => {

		if (!chatbotsLoaded) {
			return (
				<Flex w="100%" minH="300px" justifyContent="center" alignItems="center">
					<Spinner
						thickness="2px"
						speed="0.65s"
						emptyColor="gray.200"
						color="gray.700"
						size="xl" />
				</Flex>
			);
		}
		if (!chatbotsList?.length) {
			return getNoDataIcon();
		}

		const chatbotListItems = chatbotsList?.map((chatbot: any) => {
			return (
				<MediaListItem
					showCustomizeMenu
					usage={chatbot.monthUsage === undefined ? 0 : chatbot.monthUsage.count}
					name={chatbot?.name}
					owner={chatbot?.owner}
					imageAlt={chatbot?.name}
					updatedAt={chatbot?.updatedAt}
					websiteData={chatbot?.websiteData?.websiteUrl}
					showGetCodeMenu
					imageUrl={chatbot?.imageUrl}
					description={chatbot?.description}
					key={chatbot?._id}
					showWarning={chatbot?.status !== 'READY'}
					showPrimaryActionButton={chatbot?.status !== 'READY'}
					actionButtonLeftIcon={null}
					isPrimaryButtonLoading={createChatBotLoading[chatbot?._id] || chatbot?.status === 'CRAWLING'}
					onPrimaryActionButtonClick={() => {
						createChatBot(chatbot?._id)
					}}
					onMenuItemClick={(type) => {
						onMenuItemClick(type, chatbot);
					}}
				>
				</MediaListItem>
			);
		});
		return (
			<SimpleGrid columns={[1, 1, 1, 3]} spacing="6" templateColumns={{ lg: "repeat(3, minmax(0, 1fr))", sm: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))" }}>
				{chatbotListItems}
			</SimpleGrid>
		);
	}, [createChatBot, createChatBotLoading, chatbotsList, chatbotsLoaded, getNoDataIcon, onMenuItemClick]);


	return (
		<Box
			w="100%"
			maxW="100%"
			p="6"
			shadow="sm"
			h="100%"
			position="relative"
			overflow="auto"
			overflowX="hidden"
		>
			<VStack w="100%" spacing="10">
				<Flex shrink={0} w="100%" justifyContent="space-between">
					<Flex alignItems="center">
						<Heading fontSize="30">Projects</Heading>
						<Box marginLeft="15px">
							{chatbotsList?.length === undefined ? null : <Text as="span" className={styles.projectCount}>{chatbotsList?.length} Projects</Text>}
						</Box>
					</Flex>
					{user && PAID_ONLY && user?.subscriptionData?.name === 'FREE' ? <Tooltip label={"Upgrade to a paid plan to create chatbots"}>
						<Button color={"white"} backgroundColor={'#7C2FF1'} size='md' className="btn-hover-pri"><EmailPlusIcon />Create New</Button>
					</Tooltip> : <Link to="/app/create-chatbot">
						<Button color={"white"} backgroundColor={'#7C2FF1'} size='md' className="btn-hover-pri">
							<EmailPlusIcon />Create New</Button>
					</Link>}
				</Flex>
				<Box width="100%">{getChatbotsList()}</Box>
			</VStack>
		</Box>
	);
};
