import React, { ChangeEvent } from 'react'
import { OfflineMessagePagination, OfflineMessage, leads, leadsPagination } from '../../types/knowledgebase.type'
import { Alert, AlertIcon, Box, Button, Flex, HStack, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import { Paginator } from '../../widgets/Paginator/Paginator'
import { OfflineChatListItem } from './OfflineChatListItem'
import styles from "./OfflineMessagesList.module.scss"
import { IoIosSearch } from 'react-icons/io'
import { UnreadIcon } from '../Icons/UnreadIcon'
import { ReadIcon } from '../Icons/ReadIcon'
import { SearchOffLineMessage, leadsDataPagSize, leadsDataSortData } from '../../services/knowledgebaseService'
import { log } from 'console'
type OfflineMessagesListProps = {
  isChatListLoading: boolean;
  chatSessionsPage: leadsPagination;
  selectedChat: leads;
  onPageChange: (page: number) => void;
  onSelectChat: (chatSession: leads) => void;
  knowledgeBaseId: string;
  pageNumber: number;
}

export const LeadsList = ({
  isChatListLoading,
  chatSessionsPage,
  selectedChat,
  onPageChange,
  knowledgeBaseId,
  pageNumber,
  onSelectChat }: OfflineMessagesListProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [crawlDatLoading, setCrawlDatLoading] = React.useState<any>('');
  const [searchValue, setSearchValue] = React.useState('');
  const [leadsData, setLeadsData] = React.useState(chatSessionsPage);
  const [filteredLeadsData, setFilteredLeadsData] = React.useState(chatSessionsPage);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  console.log('searchValue', searchValue);

  const handleURLClick = React.useCallback(async (data: any) => {
    onOpen();
    setCrawlDatLoading(data);
    // setCrawlDatLoading(data);
    // const resposne = await fetchKnowledgebaseCrawlDataDetails(knowledgebaseId, crawlDataId);
    // setCrauledDataDetail(resposne.data?.content);
  }, [onOpen]);
  const [itemsPerPages, setItemsPerPage] = React.useState<number>(10);
  const [itemsPerPage, setItemsPerPages] = React.useState<number>(-1);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(event.target.value, 10));
  };

  const handleItemsPerPageChanges = (event: ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPages(parseInt(event.target.value, 10));
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);

    // Filter the leads data based on the search term
    const filteredData = leadsData.results.filter((chatSession: leads) =>
      chatSession.name?.toLowerCase().includes(value) ||
      chatSession.email?.toLowerCase().includes(value) ||
      chatSession.address?.toLowerCase().includes(value) ||
      chatSession.phone?.toLowerCase().includes(value)
    );
    setFilteredLeadsData({ ...leadsData, results: filteredData });
  };


  React.useEffect(() => {
    const fetchData = async () => {
      if (timeoutRef.current) {
        // Clear the previous timeout if a new one is set
        clearTimeout(timeoutRef.current);
      }
      // Set a new timeout
      timeoutRef.current = setTimeout(async () => {
        try {
          const userData = await leadsDataSortData(knowledgeBaseId, itemsPerPages, currentPage, itemsPerPage);
          setLeadsData(userData.data);
        } catch (error) {
          console.error('Failed to fetch users:', error);
        } finally {
          timeoutRef.current = null; // Reset the timeout reference
        }
      }, 500); // Delay of 1 second
    };
    fetchData();

  }, [itemsPerPages, itemsPerPage, currentPage]);

  // React.useEffect(() => {
  //   setLeadsData(chatSessionsPage);
  // }, [chatSessionsPage, onPageChange]);

  const handlePageChange = (page: number) => {
    let pages: number = page + 1;
    setCurrentPage(pages);
  };

  return (
    <Box w="100%"
      pos="relative"
    >
      {
        isChatListLoading && <Flex
          pos="absolute"
          align="center"
          justify="center"
          top={0}
          bottom={0}
          right={0}
          left={0}
          bg="whiteAlpha.700"
        >
          <Spinner />
        </Flex>
      }

      {/* <Box
                overflowY="auto"
                overflowX="hidden"
                h="calc(100% - 47px)"
                borderRight="1px"
                borderColor="gray.200"
            >
                <Flex direction="column">
                    {
                        chatSessionsPage.results
                            .map((chatSession: Leads) => (
                                <OfflineChatListItem
                                    key={chatSession._id}
                                    chatSessionData={chatSession}
                                    isSelected={selectedChat._id === chatSession._id}
                                    onSelectChat={() => onSelectChat(chatSession)}
                                />
                            ))
                    }
                </Flex>
            </Box> */}
      <Box display="flex" justifyContent="flex-end" mb="20px">
      <Text as="span" marginRight="8px">
        <Input type='text' value={searchValue} onChange={handleInputChange} placeholder="Search" />
      </Text>
        <Text as="span" marginRight="8px">
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChanges}
            width="auto"
            color="#6C757D"
            mr="8px"
          >
            <option value={-1}>New to Old</option>
            <option value={1}>Old to New</option>
          </Select>
        </Text>
      </Box>


      <Text as="div">
        <TableContainer overflow="hidden">
          <Table variant="simple" >
            <Thead>
              <Tr
                className={styles.tableHd}
              >
                <Th>#</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Address</Th>
                <Th>Phone</Th>
                <Th>Date/Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredLeadsData.results.map((chatSession: leads, index: number) => (
                <Tr key={index}
                  className={styles.tableTd}
                >
                  <Td>{index + 1 + (currentPage - 1) * itemsPerPages}</Td>
                  <Td>
                    {chatSession.name === undefined ? "-" : chatSession.name}
                  </Td>
                  <Td>
                    {/* {chatSession.email === "" ? "-" : chatSession.email} */}
                    {chatSession.email === undefined ? "-" : chatSession.email}
                  </Td>
                  <Td>
                    {/* {chatSession.address.slice(0, 30)}{chatSession.address.length > 30 && '...'} */}
                    {chatSession.address === undefined ? "-" : chatSession.address}
                  </Td>
                  <Td>
                    {/* {chatSession.phone} */}
                    {chatSession.phone === undefined ? "-" : chatSession.phone}
                  </Td>
                  <Td>{new Date(chatSession.createdAt).toLocaleString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                  })}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>

          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple" >
              <Tbody>
                <Tr
                  className={styles.tableTr}
                >
                  <Th>Name</Th>
                  <Td>
                    {/* {crawlDatLoading.name} */}
                  </Td>
                </Tr>
                <Tr
                  className={styles.tableTr}
                >
                  <Th>Email</Th>
                  {/* <Td>{crawlDatLoading.email}</Td> */}
                </Tr>
                <Tr
                  className={styles.tableTr}
                >
                  <Th>Message</Th>
                  {/* <Td>{crawlDatLoading.message}</Td> */}
                </Tr>
              </Tbody>
            </Table>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack w="full" justifyContent="space-between" p={4}>
        <Flex alignItems="center">
          <Text mr="8px" fontSize="16px" fontWeight="400" color="#212529">Total {leadsData.results.length} items</Text>
          <Paginator onPageChange={handlePageChange} pageRangeDisplayed={5} pageCount={leadsData.pages} />
        </Flex>
        <Flex alignItems="center">
          <Select
            value={itemsPerPages}
            onChange={handleItemsPerPageChange}
            width="auto"
            color="#6C757D"
            mr="8px"
          >
            <option value={10}>10 / page</option>
            <option value={20}>20 / page</option>
            <option value={50}>50 / page</option>
          </Select>
        </Flex>
      </HStack>

    </Box >
  )
}