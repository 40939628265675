import { Icon, IconProps,  } from "@chakra-ui/react";
import React from "react";

export const CircleIcon = (props: IconProps) => {
    return <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
}


export const EmailPlusIcon = () =>{
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:"10px"}}>
								<g clip-path="url(#clip0_340_9658)">
									<rect width="16" height="16" fill="white" fill-opacity="0.01" />
									<g clip-path="url(#clip1_340_9658)">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M0.585451 2.58493C0.960523 2.20986 1.46923 1.99915 1.99966 1.99915H13.9997C14.5301 1.99915 15.0388 2.20986 15.4139 2.58493C15.7889 2.96 15.9997 3.46871 15.9997 3.99915V8.49915C15.9997 8.63175 15.947 8.75893 15.8532 8.8527C15.7594 8.94647 15.6323 8.99915 15.4997 8.99915C15.3671 8.99915 15.2399 8.94647 15.1461 8.8527C15.0523 8.75893 14.9997 8.63175 14.9997 8.49915V5.38215L7.99966 9.58215L6.67366 8.78715L1.03366 12.2581C1.09068 12.4708 1.21625 12.6586 1.3909 12.7926C1.56556 12.9266 1.77954 12.9992 1.99966 12.9991H7.49966C7.63227 12.9991 7.75945 13.0518 7.85322 13.1456C7.94699 13.2394 7.99966 13.3665 7.99966 13.4991C7.99966 13.6318 7.94699 13.7589 7.85322 13.8527C7.75945 13.9465 7.63227 13.9991 7.49966 13.9991H1.99966C1.47096 13.9992 0.963761 13.7898 0.588975 13.4169C0.214188 13.044 0.00230783 12.5378 -0.000335693 12.0091V3.99915C-0.000335693 3.46871 0.210378 2.96 0.585451 2.58493ZM0.999664 11.1041L5.70766 8.20715L0.999664 5.38215V11.1041ZM0.999664 3.99915V4.21615L7.99966 8.41615L14.9997 4.21615V3.99915C14.9997 3.73393 14.8943 3.47958 14.7068 3.29204C14.5192 3.1045 14.2649 2.99915 13.9997 2.99915H1.99966C1.73445 2.99915 1.48009 3.1045 1.29256 3.29204C1.10502 3.47958 0.999664 3.73393 0.999664 3.99915Z" fill="white" />
										<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9745 14.974C15.6309 14.3176 15.9997 13.4274 15.9997 12.4991C15.9997 11.5709 15.6309 10.6806 14.9745 10.0243C14.3182 9.3679 13.4279 8.99915 12.4997 8.99915C11.5714 8.99915 10.6812 9.3679 10.0248 10.0243C9.36841 10.6806 8.99966 11.5709 8.99966 12.4991C8.99966 13.4274 9.36841 14.3176 10.0248 14.974C10.6812 15.6304 11.5714 15.9991 12.4997 15.9991C13.4279 15.9991 14.3182 15.6304 14.9745 14.974ZM12.1461 10.6456C12.2399 10.5518 12.3671 10.4991 12.4997 10.4991C12.6323 10.4991 12.7594 10.5518 12.8532 10.6456C12.947 10.7394 12.9997 10.8665 12.9997 10.9991V11.9991H13.9997C14.1323 11.9991 14.2594 12.0518 14.3532 12.1456C14.447 12.2394 14.4997 12.3665 14.4997 12.4991C14.4997 12.6318 14.447 12.7589 14.3532 12.8527C14.2594 12.9465 14.1323 12.9991 13.9997 12.9991H12.9997V13.9991C12.9997 14.1318 12.947 14.2589 12.8532 14.3527C12.7594 14.4465 12.6323 14.4991 12.4997 14.4991C12.3671 14.4991 12.2399 14.4465 12.1461 14.3527C12.0523 14.2589 11.9997 14.1318 11.9997 13.9991V12.9991H10.9997C10.8671 12.9991 10.7399 12.9465 10.6461 12.8527C10.5523 12.7589 10.4997 12.6318 10.4997 12.4991C10.4997 12.3665 10.5523 12.2394 10.6461 12.1456C10.7399 12.0518 10.8671 11.9991 10.9997 11.9991H11.9997V10.9991C11.9997 10.8665 12.0523 10.7394 12.1461 10.6456Z" fill="white" />
									</g>
								</g>
								<defs>
									<clipPath id="clip0_340_9658">
										<rect width="16" height="16" fill="white" />
									</clipPath>
									<clipPath id="clip1_340_9658">
										<rect width="16" height="16" fill="white" />
									</clipPath>
								</defs>
							</svg>
  )
}

