import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik';
import { forgotPassword } from '../../services/authServices';

interface ForgotPasswordProps {
  isOpen: boolean;
  // onOpen: () => void;
  onClose: () => void;
}

export function validateEmailAddress(email: string) {
  return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}
function validateEmail(value: string) {
  let error;
  if (!value) {
    error = 'Please enter your email.';
  } else if (validateEmailAddress(value)) {
    error = 'Please enter a valid email address.';
  }
  return error;
}

function ForgotPassword({ isOpen, onClose }: ForgotPasswordProps) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [regEmail, setRegEmail] = useState('');
  const toast = useToast();



  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Forgot Password?</ModalHeader>
          {/* <ModalCloseButton /> */}
          {!isSubmitted ? (<ModalBody textAlign={'center'}>
            <Text>Please enter your registered email address. <br />We will send you an email to reset your password.</Text>

            <Formik
              initialValues={{ email: '' }}
              onSubmit={async (values, actions) => {
                try {
                  actions.setSubmitting(false);
                  // console.log(values);
                  setRegEmail(values.email);
                  const response = await forgotPassword(values);
                  toast({
                    // title: `${error.response.data.message}`,
                    title: `${response?.data.message}`,
                    status: 'success',
                    isClosable: true,
                  });
                  setIsSubmitted(true);
                } catch (error: any) {
                  console.log(error.response?.data.message);

                  toast({
                    // title: `${error.response.data.message}`,
                    title: `${error.response?.data.message}`,
                    status: 'error',
                    isClosable: true,
                  });
                }
              }}
            >
              {(props) => (
                <Form>
                  <Field
                    type='email'
                    name='email'
                    validate={validateEmail}
                  >
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.email &&
                          form.touched.email
                        }
                        mb='26px'
                      >

                        <Input
                          {...field}
                          id='email'
                          mt={'30px'}
                          placeholder='company@mail.com'
                        />
                        <FormErrorMessage>
                          {form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <ModalFooter justifyContent={'center'}>
                    <Button type='button' backgroundColor="#efefef" mr={3} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      backgroundColor="#7C2FF1"
                      color="#fff"
                      className='btn-hover-pri'
                      type='submit'
                    >
                      Send Reset Link
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>)
            :
            (<ModalBody padding={'20px'} textAlign={'center'}>
              <Text fontWeight={500} fontSize={'20px'}>We are happy to help you reset your password!</Text>
              <Text mt={'20px'}>If you're using email and password for login, we sent the password reset email to <br /><Text display={'inline'} fontWeight={600} color='#7C2FF1'>{regEmail}</Text></Text>
              <Button mt={'20px'} role='button' onClick={() => { onClose(); setIsSubmitted(false) }}>Go to Login</Button>
            </ModalBody>)}
        </ModalContent>
      </Modal>
    </>
  )
}

export default ForgotPassword;