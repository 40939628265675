import * as React from 'react';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Progress,
	SimpleGrid,
	Input,
	Divider,
	AbsoluteCenter,
	Text,
	useToast,
	Image,
	InputRightElement,
	InputGroup,
} from '@chakra-ui/react';


import { Field, Form, Formik } from 'formik';

import styles from './SignUp.module.scss';
import { authGoogle, signUp, userPaymentstatus } from '../../services/authServices';
import {
	Link,
	RouteComponentProps,
	useHistory,
	withRouter,
} from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import { GOOGLE_AUTH_ID } from '../../config';
import LoginWithGoogle from '../LoginWithGoogle/LoginWithGoogle';
import { PricingCard } from '../../components/Pricing/PricingCard';
import { SubscriptionTier, SubscriptionType } from '../../types/subscription.type';
import { PricingApiCard } from '../../components/Pricing/PricingApiCard';
import Lottie from 'lottie-react';
import animationData from './Animation.json';
import { error } from 'console';
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}
interface FormValues {
	email: string;
	password: string;
}
interface SignUpProps extends RouteComponentProps {
	onSignUp: (token: string, redirectTo?: string) => void;
}


function validateEmail(value: string) {
	let error;
	if (!value) {
		error = 'Please enter your email.';
	} else if (validateEmailAddress(value)) {
		error = 'Please enter a valid email address.';
	}
	return error;
}
function validatePassword(value: string) {
	let error;
	if (!value) {
		error = 'Please enter a password.';
	}
	return error;
}
const SignUp = (props: SignUpProps) => {
	const toast = useToast();
	let history = useHistory();
	const [subscriptionTier, setSubscriptionTier] = React.useState<SubscriptionTier>('MONTHLY');
	const [singUpDiv, setSingUpDiv] = React.useState(true);
	const [priceDiv, setPriceDiv] = React.useState(false);
	const [animationDiv, setAnimationDiv] = React.useState(false);
	const [progressBar, setProgressBar] = React.useState(33);
	const [userEmail, setUserEmail] = React.useState<any>(null);
	const [show, setShow] = React.useState(false);
	const isCurrentPlan = React.useCallback((type: SubscriptionType) => {
		// return currentSubscription?.name === type;
	}, []);


	const responseGoogleSuccess = React.useCallback((googleResponse: any) => {

		async function googleLogin() {
			try {
				const response: any = await authGoogle({
					token: googleResponse.access_token,
				});
				props.onSignUp(response.data.access);

			} catch (error: any) {
				let title = 'Oops! Something went wrong!';
				const errors = error?.response?.data?.nonFieldErrors;
				if (errors) {
					title = errors
				}
				toast({
					title: title,
					status: "error",
					isClosable: true,
				});
			}
		}
		googleLogin();
	}, [history, props, toast])

	const responseGoogleFailure = React.useCallback((error) => {

		let title = 'Oops! Something went wrong!';
		toast({
			title: title,
			status: "error",
			isClosable: true,
		});
	}, [toast]);
	const goToDashboard = () => {
		history.push('/');
	}

	const handleClick = () => setShow(!show);
	const checkSubscriptionStatus = async () => {
		try {
			const response: any = await userPaymentstatus();

			if (response?.data?.status == 'active') {

				// Only call paymentStatus if the subscription is active
				showAnimation();
				clearInterval(intervalId);
			} else {
				// Handle the case where the subscription is not active, if necessary
			}
		} catch (error) {
			console.error('Error fetching payment status:', error);
			// Handle errors if the request fails
		}
	}

	const intervalId = setInterval(() => {
		if (progressBar == 66.2) {
			checkSubscriptionStatus();
		} else if (progressBar > 66.2) {
			clearInterval(intervalId); // Clear the interval when the condition is no longer met
		}
		else {
			return null;
		}
	}, 10000);



	const singupUser = () => {
		setProgressBar(66.2);
		setSingUpDiv(false);
		setPriceDiv(true);
		checkSubscriptionStatus(); // Call the function to check the subscription status
	}
	const showAnimation = () => {
		setProgressBar(100);
		setSingUpDiv(false);
		setPriceDiv(false);
		setAnimationDiv(true)
	}

	function validatePassword(value: string) {
		let error;
		if (!value) {
			error = "Please enter a password.";
		} else if (value.length < 8) {
			error = "Password must be at least 8 characters.";
		} else if (!/[A-Z]/.test(value)) {
			error = "Password must contain at least one uppercase letter.";
		} else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
			error = "Password must contain at least one special character.";
		}
		return error;
	}

	// function validatePassword(password: string): string {
	// 	const errors: string[] = [];

	// 	if (password.length < 8 || password.length > 16) {
	// 		errors.push("Password must be between 8 and 16 characters long");
	// 	}

	// 	if (!/[A-Z]/.test(password)) {
	// 		errors.push("Password must contain at least one uppercase letter (A-Z).");
	// 	}

	// 	if (!/[a-z]/.test(password)) {
	// 		errors.push("Password must contain at least one lowercase letter (a-z).");
	// 	}

	// 	if (!/\d/.test(password)) {
	// 		errors.push("Password must include at least one digit (0-9).");
	// 	}

	// 	if (!/[@$!%*?&]/.test(password)) {
	// 		errors.push("Password must include at least one special character (e.g., @, $, !, %, *, ?, &, etc.).");
	// 	}

	// 	// Join the errors with <br /> to create line breaks
	// 	return errors.join("<br />");
	// }


	return (
		<Flex bg='#F7FAFC' h='100vh' direction='column' overflow={'auto'}>
			<Flex
				shrink={0}
				shadow='base'
				w='100%'
				h='60px'
				bg='white'
				pr='10'
				pb='4'
				pt='4'
				pl='10'
				zIndex='2'
				alignItems='center'
			>
				<Logo></Logo>
			</Flex>
			<Box overflow={'auto'}>
				<Box textAlign="center">
					<Heading
						textAlign='center'
						fontSize='30px'
						as='h3'
						mt='24px'
						mb="4px"
						color="#1A202C"
					>
						Create Account
					</Heading>
					<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D">
						Unlock 24/7 Customer & Sales Support For Your Business Today!
					</Text>
					<Box width="100%" marginTop="72px" marginBottom="78px">
						<Box position="relative" margin="0px 154px">
							{/* White Background behind Progress Bar */}
							<Box position="absolute" bottom="100%" left="0" width="100%" height="48px" backgroundColor="white" borderRadius="2px" />

							{/* Progress Bar */}
							<Progress value={progressBar} colorScheme="purple" height="10px" border="2px solid #7C2FF1" />

							{/* Dividers */}
							<Box position="absolute" top="0" width="100%" height="100%">
								<Box position="absolute" left="33%" height="100%" borderLeft="2px solid #7C2FF1" />
								<Box position="absolute" left="66%" height="100%" borderLeft="2px solid #7C2FF1" />
							</Box>

							{/* Step Labels */}
							<Flex justify="space-around" position="absolute" top="0" width="100%" mt="14px">
								<Text fontSize="16px" fontWeight="bold" color="#212529">Sign up</Text>
								<Text fontSize="16px" fontWeight="bold" color="#212529">Payment</Text>
								<Text fontSize="16px" fontWeight="bold" color="#212529">Complete</Text>
							</Flex>
						</Box>
					</Box>


				</Box>

				<Flex alignItems='center' justifyContent='center'
					display={singUpDiv === true ? "block" : "none"}
				>
					<Box
						bg='#fff'
						p='24px 24px '
						shadow='md'
						width={476}
						maxW="90vw"
						borderRadius='4px'
						m='0 auto'
						border="2px solid #DEE2E6"
						boxShadow="none"
					>
						<Heading
							textAlign='left'
							fontSize='20px'
							as='h5'
							isTruncated
							mb='4px'
							fontWeight="600"
							color="#1A202C"
						>
							Create Account
						</Heading>
						<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D" mb="24px">
							Create a Ultima Chatbot account to create the projects.
						</Text>
						<Formik
							initialValues={{ email: '', password: '' }}
							onSubmit={async (values: FormValues, actions) => {
								try {
									const response = await signUp({
										email: values.email,
										password: values.password,
										confirmPassword: values.password,
										avatarUrl: `https://api.dicebear.com/9.x/initials/svg?seed=${values.email}&radius=50&backgroundColor=D6BFFB&textColor=7C2FF1&chars=1`
									});

									setUserEmail(response.data.email);
									props.onSignUp(response.data.access);
									singupUser();
									actions.setSubmitting(false);

								} catch (error: any) {
									validatePassword(values.password);
									toast({
										// title: `${error.response.data.message}`,
										title: `User with this email already exists`,
										status: 'error',
										isClosable: true,
									});
									actions.setSubmitting(false);
								}
							}}
						>
							{(props) => (
								<Form>
									<Field
										type='email'
										name='email'
										validate={validateEmail}
									>
										{({ field, form }: any) => (
											<FormControl
												mb='6'
												isInvalid={
													form.errors.email &&
													form.touched.email
												}
											>
												<FormLabel
													// fontSize='sm'
													htmlFor='email'
													fontSize='16px'
													fontWeight="400"
													color="#212529"
												>
													Email Address
												</FormLabel>
												<Input
													{...field}
													id='email'
													placeholder='company@mail.com'
												/>
												<FormErrorMessage>
													{form.errors.email}
												</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field
										type='password'
										name='password'
										validate={validatePassword}
									>
										{({ field, form }: any) => (
											<FormControl
												mb='6'
												isInvalid={
													form.errors.password &&
													form.touched.password
												}
											>
												<FormLabel
													// fontSize='sm'
													htmlFor='password'
													fontSize='16px'
													fontWeight="400"
													color="#212529"
												>
													Password
												</FormLabel>
												{/* <Input
													{...field}
													type='password'
													id='password'
													placeholder='********'
												/> */}
												<InputGroup size='md'>
													<Input
														pr='4.5rem'
														{...field}
														type={show ? 'text' : 'password'}
														placeholder='********'
													/>
													<InputRightElement>
														<Box onClick={handleClick}>
															{show ? <><svg width="16px" height="16px" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M1 1.27L2.28 0L19 16.72L17.73 18L14.65 14.92C13.5 15.3 12.28 15.5 11 15.5C6 15.5 1.73 12.39 0 8C0.69 6.24 1.79 4.69 3.19 3.46L1 1.27ZM11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14.0005 8.34057 13.943 8.67873 13.83 9L10 5.17C10.3213 5.05698 10.6594 4.99949 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C21.1834 10.0729 19.7966 11.8723 18 13.19L16.58 11.76C17.9629 10.8034 19.0783 9.5091 19.82 8C19.0117 6.34987 17.7565 4.95963 16.1974 3.98735C14.6382 3.01508 12.8375 2.49976 11 2.5C9.91 2.5 8.84 2.68 7.84 3L6.3 1.47C7.74 0.85 9.33 0.5 11 0.5ZM2.18 8C2.98835 9.65014 4.24345 11.0404 5.80264 12.0126C7.36182 12.9849 9.16251 13.5002 11 13.5C11.69 13.5 12.37 13.43 13 13.29L10.72 11C10.0242 10.9254 9.37482 10.6149 8.87997 10.12C8.38512 9.62518 8.07458 8.97584 8 8.28L4.6 4.87C3.61 5.72 2.78 6.78 2.18 8Z" fill="#6C757D"/>
																		</svg>
																		</> : <><svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clip-path="url(#clip0_1003_1703)">
																	<path d="M15.9998 7.99915C15.9998 7.99915 12.9998 2.49915 7.99976 2.49915C2.99976 2.49915 -0.000244141 7.99915 -0.000244141 7.99915C-0.000244141 7.99915 2.99976 13.4991 7.99976 13.4991C12.9998 13.4991 15.9998 7.99915 15.9998 7.99915ZM1.17276 7.99915C1.65627 7.26427 2.2124 6.57983 2.83276 5.95615C4.11976 4.66715 5.87976 3.49915 7.99976 3.49915C10.1198 3.49915 11.8788 4.66715 13.1678 5.95615C13.7881 6.57983 14.3442 7.26427 14.8278 7.99915C14.7698 8.08615 14.7058 8.18215 14.6328 8.28715C14.2978 8.76715 13.8028 9.40715 13.1678 10.0421C11.8788 11.3311 10.1188 12.4991 7.99976 12.4991C5.87976 12.4991 4.12076 11.3311 2.83176 10.0421C2.2114 9.41846 1.65628 8.73402 1.17276 7.99915Z" fill="#6C757D" />
																	<path d="M7.99976 5.49915C7.33671 5.49915 6.70083 5.76254 6.23199 6.23138C5.76315 6.70022 5.49976 7.3361 5.49976 7.99915C5.49976 8.66219 5.76315 9.29807 6.23199 9.76691C6.70083 10.2358 7.33671 10.4991 7.99976 10.4991C8.6628 10.4991 9.29868 10.2358 9.76752 9.76691C10.2364 9.29807 10.4998 8.66219 10.4998 7.99915C10.4998 7.3361 10.2364 6.70022 9.76752 6.23138C9.29868 5.76254 8.6628 5.49915 7.99976 5.49915ZM4.49976 7.99915C4.49976 7.07089 4.86851 6.18065 5.52488 5.52427C6.18126 4.86789 7.0715 4.49915 7.99976 4.49915C8.92801 4.49915 9.81825 4.86789 10.4746 5.52427C11.131 6.18065 11.4998 7.07089 11.4998 7.99915C11.4998 8.9274 11.131 9.81764 10.4746 10.474C9.81825 11.1304 8.92801 11.4991 7.99976 11.4991C7.0715 11.4991 6.18126 11.1304 5.52488 10.474C4.86851 9.81764 4.49976 8.9274 4.49976 7.99915Z" fill="#6C757D" />
																</g>
																<defs>
																	<clipPath id="clip0_1003_1703">
																		<rect width="16" height="16" fill="white" />
																	</clipPath>
																</defs>
															</svg></>}
														</Box>
													</InputRightElement>
												</InputGroup>
												<FormErrorMessage>
													<Text as="span" dangerouslySetInnerHTML={{ __html: form.errors.password }} />
												</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Button
										backgroundColor="#7C2FF1"
										color={"white"}
										variant='solid'
										mt={4}
										isFullWidth
										size='lg'

										type='submit'

										className='btn-hover-pri'
									>
										Create Account
									</Button>

								</Form>
							)}
						</Formik>
						<Box>
							<Box position='relative' mt="30.5px" mb="30.5px">
								<Divider color="#E2E8F0" />
								<AbsoluteCenter bg='white' px='4' color="#6C757D" fontSize="14px">
									Already have an account?
								</AbsoluteCenter>
							</Box>
							<Link to='/login'>
								<Button
									backgroundColor="white"
									color={"#7C2FF1"}
									variant='solid'
									border="2px solid"

									isFullWidth
									size='lg'
									className='btn-hover-sen'
								>
									Sign in
								</Button>
							</Link>
						</Box>
					</Box>
				</Flex>
				<Flex display={priceDiv === true ? "block" : "none"} >
					<Box
						bg='#fff'
						p='24px 24px '
						shadow='md'
						width={1040}

						borderRadius='4px'
						m='0 auto'
						marginBottom="24px"
						border="2px solid #DEE2E6"
						boxShadow="none"
					>
						<Flex>
							<Box>
								<Text as="h5" fontSize="20px" fontWeight="600" color="#1A202C" onClick={showAnimation}>
									Pricing
								</Text>
								<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D">
									Select your payment plans
								</Text>
							</Box>
						</Flex>
						<SimpleGrid columns={[1, 1, 1, 3]} spacing="6" marginTop="24px" templateColumns={{ lg: "repeat(3, minmax(0, 1fr))", sm: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))" }}>
							<PricingCard
								tier="MONTHLY"
								subscriptionData={{
									type: 'Standard',
									pageCount: 1000,
									tokenSize: '1M',
									projectCount: 1
								}}
								discountData={undefined}
								isCurrentSubscription={false}
								userEmail={userEmail}
							/>
							<PricingCard
								tier={subscriptionTier}
								subscriptionData={{
									type: 'Premium',
									pageCount: 2500,
									tokenSize: '2.5M',
									projectCount: 3
								}}
								discountData={undefined}
								isCurrentSubscription={false}
								userEmail={userEmail}
								isPopular
							/>
							<PricingCard
								tier={subscriptionTier}
								subscriptionData={{
									type: 'Enterprise',
									pageCount: 10000,
									tokenSize: '10M',
									projectCount: "Unlimited"
								}}
								discountData={undefined}
								isCurrentSubscription={false}
								userEmail={userEmail}
							/>
						</SimpleGrid>
						<Box position='relative' mt="20px" mb="20px">
							<Divider color="#E2E8F0" />
							<AbsoluteCenter bg='white' px='4' color="#6C757D" fontSize="14px">
								API Key Plan
							</AbsoluteCenter>
						</Box>
						<SimpleGrid columns={[1, 1, 1, 3]} spacing="6" marginTop="24px" templateColumns={{ lg: "repeat(3, minmax(0, 1fr))", sm: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))" }}>
							<PricingApiCard
								tier={subscriptionTier}
								subscriptionData={{
									type: 'Standard',
									pageCount: 1000,
									tokenSize: '1M',
									projectCount: 1
								}}
								discountData={undefined}
								isCurrentSubscription={false}
								userEmail={userEmail}
							/>
							<PricingApiCard
								tier={subscriptionTier}
								subscriptionData={{
									type: 'Premium',
									pageCount: 2500,
									tokenSize: '2.5M',
									projectCount: 3
								}}
								discountData={undefined}
								isCurrentSubscription={false}
								userEmail={userEmail}
								isPopular
							/>
							<PricingApiCard
								tier={subscriptionTier}
								subscriptionData={{
									type: 'Enterprise',
									pageCount: 10000,
									tokenSize: '10M',
									projectCount: "Unlimited"
								}}
								discountData={undefined}
								isCurrentSubscription={false}
								userEmail={userEmail}
							/>
						</SimpleGrid>
					</Box>

				</Flex>
				<Flex display={animationDiv === true ? "block" : "none"} >
					<Box
						bg='#fff'
						p='24px 24px '
						shadow='md'
						width={1040}
						// maxW="90vw"
						borderRadius='4px'
						m='0 auto'
						marginBottom="24px"
						border="2px solid #DEE2E6"
						boxShadow="none"
						textAlign="center"
					>
						<Box

							className={styles.animationImg}
						>
							<Lottie animationData={animationData} style={{ width: 130, height: 130 }} />
						</Box>
						{/* <Image src={animation} alt="Animated Gif" boxSize="200px" /> */}
						<Text as="p" fontSize="20px" fontWeight="600" color="#1A202C">Congratulations!</Text>
						<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D">Your profile is complete.</Text>
						<Button
							backgroundColor="#7C2FF1"
							color={"white"}
							variant='solid'
							mt="24px"
							// isFullWidth
							// size='lg'
							fontSize="14px"
							className='btn-hover-pri'
							onClick={goToDashboard}
						>
							Go To Dashboard
						</Button>
					</Box>
				</Flex>
      </Box>
		</Flex>

	);
};
export default withRouter(SignUp);
